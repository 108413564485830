import * as React from "react"
import { Link, graphql } from "gatsby"

import { TagList } from "../components/tag-list"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  // 未設定の場合はドラフトを出力したくないので GATSBY_GENERATE_DRAFT は "no" にデフォルトする。
  const shouldShowDraftPages = (process.env.GATSBY_GENERATE_DRAFT ?? "no") === "yes"

  const posts = shouldShowDraftPages
    ? data.allMarkdownRemark.nodes
    : data.allMarkdownRemark.nodes.filter($0 => {
      // frontmatter に draft が未設定の場合は
      // ドラフトとして扱いたいので true にデフォルトする。
      return ($0.frontmatter.draft ?? true) === false
    })

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const titlePrefix = (post.frontmatter.draft ?? true) ? "(draft) " : "";
          const title = `${titlePrefix}${post.frontmatter.title || post.fields.slug}`

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <Link to={post.fields.slug} itemProp="url">
                  <header>
                    <h2>
                      <span itemProp="headline">{title}</span>
                    </h2>
                    <small>{post.frontmatter.date}</small>
                    <TagList tags={post.frontmatter.tags} />
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </Link>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          draft
        }
      }
    }
  }
`
