import * as React from "react"

const Tag = ({title}) => {
    return <span className="tag"><small>[{title}]</small></span>;
};

export const TagList = ({tags}) => {
    return <span className="tag-list-container">
        {tags.map(($0, idx) => {
            return <Tag key={idx} title={$0} />;
        })}
    </span>;
};

export default Tag;
